import Vue from "vue";

const firebaseConfig = {
  apiKey: "AIzaSyBhtHD4phsMVhTmUsgV0PAEPSBPgbd-gPY",
  authDomain: "ming-tak-development.firebaseapp.com",
  databaseURL: "https://ming-tak-development-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "ming-tak-development",
  storageBucket: "ming-tak-development.appspot.com",
  messagingSenderId: "641046719668",
  appId: "1:641046719668:web:fb18fefd976955f7c2fbe1",
  measurementId: "G-TNK1SY8JFQ"
};

Vue.mixin({
  data: function () {
    return {
      get companyName() {
        return "MING TAK DEVELOPMENT"; //公司名稱
      },
      get regFormDomain() {
        return "https://mingtak-develop.xyz/"; 
      },
    };
  },
});

//  溫馨提示：建議使用較淺的顏色
//  歡迎回來的背景顏色
const panelColor        = "#e8f2fa"

//  溫馨提示：建議使用較深的顏色
//  側邊欄的背景顏色
const sideBarColor      = "#000"

//        /||\
//       / || \
//      |------|
//      |  ||  |
//      |  ||  |
//      |  ||  |
//      |  ||  |
//       \ || /
//  不用理 \||/
//        /||\
//       / || \
export {
    firebaseConfig,
    panelColor,
    sideBarColor
}